import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Login from "./Components/Admin/Login/Login";
import IndexComplaint from "./Components/complaint-manage-dashboard/IndexComplaint";
import IndexLead from "./Components/lead-dashboard/IndexLead";
import IndexAdmin from "./Components/Admin/IndexAdmin";
import { Context } from "./utils/context";
import ComplaintRoutes from "./Components/complaint-management/ComplaintRoutes";
import { IDS, Roles } from "./utils/common";
import Super_admin_main_routes from "./Components/Super-Admin/Super-admin-main-routes/Super_admin_main_routes";
import ProjectManagementRoutes from "./Components/Project-Management/ProjectManagementRoutes";
import Hmain_Routes from "./Components/Hrms-Dashboard/Hrms-Main-Routes/Hmain_Routes";
import PageNotFound from "./Components/PageNotFound/PageNotFound";


const App = () => {
  const { signin, usertype, isAllow } = useContext(Context);
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]); // Add location.pathname as a dependency

  console.log("userTypeeee:---:::", usertype);


  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>

      {signin ? (
        <>
          {usertype === Roles.SuperAdmin && (
            <Routes>
              <Route
                path="/super-admin/*"
                element={<Super_admin_main_routes />}
              />
            </Routes>
          )}
          {usertype === Roles.TeleCaller && <IndexLead />}
          {usertype === Roles.Complaint && <ComplaintRoutes />}
          {usertype === Roles.Admin && <IndexAdmin />}
          {usertype === Roles.ProjectManager && <ProjectManagementRoutes />}
          {usertype === Roles.HRMS && (
            <Routes>
              <Route path="/hrms-admin/*" element={<Hmain_Routes />} />
            </Routes>
          )}
        </>
      ) : null}
      {/* <Routes>
        <Route path="*" element={<PageNotFound />} />
      </Routes> */}
    </>
  );
};

export default App;
