import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Top_header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

const Top_header = () => {
  const navigate = useNavigate();
  const {
    postData,
    signin,
    setSignin,
    usertype,
    setUsertype,
    IMG_URL,
    setUserData,
    userdata,
    getData,
  } = useContext(Context);

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  return (
    <>
      <section className="super_Top_headsec">
        <div className="row">
          <div className="col-md-4">
            <div className="">
              <FontAwesomeIcon
                className="sidebaradjusticon"
                icon={faBarsStaggered}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="headsearchdiv">
              <Form.Control type="search" placeholder="Search here..." />
              <button className="searchbtn">Search</button>
            </div>
          </div>
          {/* <div className="col-md-4 text-end">
            <div className="logouticon">
              <FontAwesomeIcon className="logicn" icon={faRightFromBracket} />
            </div>
          </div> */}

          <div className="col-md-4 text-end">
            <button
              type="button"
              onClick={LogOut}
              className="next-btn-class mt-3"
            >
              <div className="logouticon d-flex">
                <FontAwesomeIcon className="logicn ms-2" icon={faRightFromBracket} />
              </div>{" "}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Top_header;
