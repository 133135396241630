import { Modal } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import "../../../../Admin/Tabels/Tabels.css";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../common/ModalSave";
import { CancelButton } from "../../../../common/Button";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";

library.add(fas);

const EditModal = (props) => {
  const { postData, getData } = useContext(Context);
  const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm();

  useEffect(() => {
    const GetEditData = async () => {
      const response = await getData(`/hrms/device-control/1`);
      reset(response?.data);
    };
    GetEditData()
  }, []);

  const onSubmit = async (data) => {
    try {
      // const sendData = new FormData();
      // sendData.append("name", data?.name);

      const response = await postData(`/hrms/device-control/1`, data);
      console.log(response);

      if (response?.success) {
        setShowLottie({ show: true, msg: response?.message, success: response?.success });
      } else {
        setShowLottie({ show: true, msg: response?.message, success: response?.success });
      }
      setTimeout(() => {
        setShowLottie({ show: false, msg: "", success: false });
        props.handleClose()
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
        onHide={() => {
          props.handleClose();
          reset();
        }}
      >
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Edit Device Control</h4>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={8} md={10} className="mx-auto Add-content">
                      <div className="main-form-section mt-5">
                        <Form.Label>User Name</Form.Label>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="UserName"
                              placeholder="User Name"
                              className={classNames("", {
                                "is-invalid": errors?.UserName,
                              })}
                              {...register("UserName", {
                                required: "User Name is required",
                                validate: (value) => {
                                  if (value.includes(" ")) {
                                    return "Spaces are not allowed";
                                  }
                                  return true;
                                },
                              })}
                              onKeyDown={(e) => {
                                // Prevent space key
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </InputGroup>

                          {errors.UserName && (
                            <span className="text-danger">
                              {errors.UserName.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="main-form-section mt-5">
                        <Form.Label>User Password</Form.Label>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="UserPassword"
                              placeholder="User Password"
                              className={classNames("", {
                                "is-invalid": errors?.UserPassword,
                              })}
                              {...register("UserPassword", {
                                required: "User Password is required",
                                validate: (value) => {
                                  if (value.includes(" ")) {
                                    return "Spaces are not allowed";
                                  }
                                  return true;
                                },
                              })}
                              onKeyDown={(e) => {
                                // Prevent space key
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </InputGroup>
                          {errors.UserPassword && (
                            <span className="text-danger">
                              {errors.UserPassword.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="main-form-section mt-5">
                        <Form.Label>Device Serial No.</Form.Label>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="SerialNumber"
                              placeholder="Device Serial No."
                              className={classNames("", {
                                "is-invalid": errors?.SerialNumber,
                              })}
                              {...register("SerialNumber", {
                                required: "Device Serial No is required",
                                validate: (value) => {
                                  if (value.includes(" ")) {
                                    return "Spaces are not allowed";
                                  }
                                  return true;
                                },
                              })}
                              onKeyDown={(e) => {
                                // Prevent space key
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                              }}
                            />
                          </InputGroup>
                          {errors.SerialNumber && (
                            <span className="text-danger">
                              {errors.SerialNumber.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="d-flex justify-content-center mt-5 pb-3">
                        <CancelButton
                          name="cancel"
                          handleClose={() => {
                            props.handleClose();
                            reset();
                          }}
                        />
                        <button type="submit" className="save-button">
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <AddedLottieModal
        pageTitle="Designation"
        message={showLottie.msg}
        show={showLottie.show}
        success={showLottie.success}
        onHide={() => setShowLottie({ show: false, msg: "", success: false })}
      />
    </section>
  );
};

export default EditModal;
