import { Modal } from "react-bootstrap";
import "../../../../CommonCss/ModalCommonCss.css";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../../common/ModalSave";
import AddedLottieModal from "../../../../commonLottie/AddedLottieModal";

const AddDesignation = (props) => {
  const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });
  const { postData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const sendData = new FormData();
      sendData.append("name", data?.name);

      const response = await postData(`/hrms-masters/designation`, sendData);
      console.log(response);

      if (response?.success) {
        props.onHide();
        setShowLottie({ show: true, msg: response?.message, success: true });
        reset();
      } else {
        setShowLottie({ show: true, msg: response?.message, success: false });
      }

      // Automatically reset showLottie after 1000 milliseconds
      setTimeout(() => {
        setShowLottie({ show: false, msg: "", success: false });
      }, 1000);
    } catch (error) {
      console.log(error);
      setShowLottie({ show: false, msg: "Error Submitting", success: false });
    }
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
      >
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Add Designation</h4>
          </div>

          <Container>
            <Row>
              <Col md={12}>
                <Container>

                  <Form onSubmit={handleSubmit(onSubmit)} role="form">
                    <Row>
                      <Col lg={8} md={10} className=" mx-auto Add-content">
                        <Row>
                          <div className="main-form-section mt-5">
                            <Form.Label>Designation Name</Form.Label>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Designation"
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Designation is required",
                                    pattern: {
                                      value: /^[A-Za-z\s]{1,100}$/, // Regex to allow only letters and spaces (and limit to 100 characters)
                                      message: "Name must only contain letters and spaces, and be no longer than 100 characters", // Custom error message
                                    },
                                  })}
                                  onKeyDown={(e) => {
                                    // Allow: backspace, tab, enter, escape, space, and letters (A-Z, a-z)
                                    if (
                                      e.key === "Backspace" ||
                                      e.key === "Tab" ||
                                      e.key === "Enter" ||
                                      e.key === "Escape" ||
                                      e.key === " " ||
                                      /^[A-Za-z]$/.test(e.key) ||
                                      e.key === "ArrowLeft" ||
                                      e.key === "ArrowRight" ||
                                      e.key === "Delete" ||
                                      e.key === "ArrowUp" ||
                                      e.key === "ArrowDown"
                                    ) {
                                      const currentLength = e.target.value.length;
                                      if (currentLength >= 100) {
                                        if (
                                          e.key === "Backspace" ||
                                          /^[A-Za-z]$/.test(e.key) ||
                                          e.key === "ArrowLeft" ||
                                          e.key === "ArrowRight" ||
                                          e.key === "Delete" ||
                                          e.key === "ArrowUp" ||
                                          e.key === "ArrowDown"
                                        ) {
                                          return;
                                        }
                                        e.preventDefault();
                                      }
                                      return;
                                    } else {
                                      e.preventDefault();
                                    }
                                  }}
                                  maxLength={100}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </div>
                        </Row>

                        <Row className="mt-5 pb-3">
                          <div className="d-flex justify-content-center">
                            <Link>
                              <button
                                className="cancel-button me-2"
                                onClick={() => { props.onHide(); reset() }}
                              >
                                Cancel
                              </button>
                            </Link>

                            <button
                              className="save-button"
                              name={"save"}
                              type="submit"
                            >
                              Save
                            </button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Form>

                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <AddedLottieModal
        pageTitle="Designation"
        message={showLottie.msg}
        show={showLottie.show}
        success={showLottie.success}
        onHide={() => setShowLottie({ show: false, msg: "", success: false })}
      />
    </section>
  );
};

export default AddDesignation;
