import React from "react";
import { Navbar } from "react-bootstrap";
import "./HrmsHeader.css";
import {
    faBell,
    faCircleUser,
    faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const HrmsHeader = () => {
    return (
        <section className="hrms-header">
            <Navbar className="bg-body-tertiary">
                <Navbar.Brand>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/hrms-dashboard/Image/final-Logo.png"
                        }
                        className="logo-img"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse className="justify-content-end" id="navbarScroll">
                    <Navbar.Text>
                        <div className='d-flex align-items-center'>
                            {/* <FontAwesomeIcon icon={faGear} className='header-iconnn' />
                            <div className='main-notification-icon'>
                                <FontAwesomeIcon icon={faBell} className='header-iconnn' />
                                <div className='red-dot'></div>
                            </div> */}
                            <Link to='/hrms-admin/hrms-profile'>
                                <FontAwesomeIcon icon={faCircleUser} className='header-iconnn-user' />
                            </Link>
                        </div>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
        </section>
    );
};


export default HrmsHeader
