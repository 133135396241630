import "../../CommonCss/CommonCss.css";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import AddModal from "./prefix/AddModal";
import EditModal from "./DeviceControlModal/EditModal";
import DeleteModal from "../../../common/DeleteModal";
import Dropdown from "react-bootstrap/Dropdown";

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";

import { Context } from "../../../../utils/context";

import { Link, useNavigate, useParams } from "react-router-dom";

const DeviceControl = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState({ show: false, heading: "", id: "" });

  const getDataAll = async () => {
    const response = await getData(
      `/hrms/device-control?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  const handleClose1 = () => setEditModalShow(0);
  const handleShow1 = (id) => {
    setEditModalShow(id);
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, editmodalShow, addmodalShow]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/hrms/device-control/${id}`);
    setChangeStatus(response);
  };

  const handleDeleteRecord = async (id) => {
    const response = await deleteData(
      `/hrms/device-control/${id}`
    );
    setDeleteModalShow({ show: false, heading: "", id: "" })
    setChangeStatus(response);
  }


  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={7} xl={6} lg={4} md={12} sm={12} xs={12}>
            <p className="main-table-head">Device Controls</p>
          </Col>
          {/* <AddModal show={addmodalShow} onHide={() => setAddModalShow(false)} /> */}
        </Row>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr className="">
                <th className="tax-name">User Name</th>
                <th className="tax-name">User Password</th>
                <th className="tax-name">Serial No.</th>
                <th className="active">Action</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              {isAllow.includes(73) ? (
                data?.data?.data?.map((d, index) => {
                  return (
                    <tr className="" key={index}>
                      <td>{d?.UserName}</td>
                      <td>{d?.UserPassword}</td>
                      <td>{d?.SerialNumber}</td>
                      <td>
                        <div className="d-flex">
                          {isAllow.includes(75) ? (
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="table-iconnnn "
                              onClick={() => handleShow1(d?.id)}
                              // onClick={() => handleShow1(d?.id)}
                              type="button"
                            />
                          ) : (
                            // <img src={pen} className="pen" alt="" />

                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          <div className="d-flex-row">
            <p>* This device control is dynamic data of the credentials and the serial no of the attendance device</p>
            <p>* This will not update the credentials in the device</p>
            <p>* You have to provide the correct user name ,user password and serial no to connect the device</p>
          </div>
        </div>
      </div>
      {isAllow.includes(247) ? (
        <EditModal
          show={editmodalShow}
          handleClose={handleClose1}
          setShow={setEditModalShow}
        />
      ) : (
        ""
      )}

      <DeleteModal
        heading={deleteModalShow?.heading}
        show={deleteModalShow?.show}
        onHide={() => setDeleteModalShow({ show: false, heading: "", id: "" })}
        handleDelete={() => handleDeleteRecord(deleteModalShow?.id)}
      />
    </section>
  );
};

export default DeviceControl;
