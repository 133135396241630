import "../../CommonCss/CommonCss.css";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  faArrowUpWideShort,
  faDownload,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { faRectangleList } from "@fortawesome/free-regular-svg-icons";

import UploadModal from "./AttendanceModals/UploadModal";

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Admin/Tabels/Tabels.css";

import search1 from "../../../../Components/Admin/assets/icons/search.png";

import Header from "../../../Admin/Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";

import "../../../Admin/HRM/AttendanceTable/AttendanceTable.css";
import { Link } from "react-router-dom";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";

import Select from "react-select";
import { Select2Data } from "../../../../utils/common";

const Attendance = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    getDownloadDataExcel,
  } = useContext(Context);

  const [days, setDays] = useState([]);
  const [show3, setShow3] = useState(0);
  const [users, setUsers] = useState([]);
  const [data, setSetData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([
    {
      value: 1,
      name: "month",
      label: "January",
    },

    {
      value: 2,
      name: "month",
      label: "February",
    },

    {
      value: 3,
      name: "month",
      label: "March",
    },

    {
      value: 4,
      name: "month",
      label: "April",
    },

    {
      value: 5,
      name: "month",
      label: "May",
    },

    {
      value: 6,
      name: "month",
      label: "June",
    },

    {
      value: 7,
      name: "month",
      label: "July",
    },

    {
      value: 8,
      name: "month",
      label: "August",
    },

    {
      value: 9,
      name: "month",
      label: "September",
    },

    {
      value: 10,
      name: "month",
      label: "October",
    },

    {
      value: 11,
      name: "month",
      label: "November",
    },

    {
      value: 12,
      name: "month",
      label: "December",
    },
  ]);

  const [user, setUser] = useState([]);

  const [currentdate, setCurrentDate] = useState(0);

  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);

  const [searchuser, setSearchuser] = useState([]);
  const [searchmonth, setSearchmonth] = useState([]);
  const [searchyears, setSearchyears] = useState([]);

  const getDataAll = async () => {
    const responseUsers = await getData("/hrms-masters/attendance");
    const response = await getData(
      `/hrms-masters/attendance?user=${searchuser.value ? searchuser.value : ""
      }&month=${searchmonth.value ? searchmonth.value : ""}&year=${searchyears.value ? searchyears.value : ""
      }`
    );

    await setDays(response?.data?.monthDetailsArray);
    await setUsers(response?.data?.structuredData);
    await setSetData(response?.data);

    response?.data?.monthDetailsArray?.map((day, index) =>
      day.isCurrentDate ? setCurrentDate(day.date) : null
    );

    const years = [];

    let no = 2020;
    while (no <= new Date().getFullYear()) {
      years.push({
        value: no,
        name: "year",
        label: no,
      });

      ++no;
    }

    setYears(years);

    // const allusers = await getData("/masters/allemployees");

    // const option = [];
    // if (allusers.success) {
    //   allusers?.data?.map((data) => {
    //     option.push({
    //       value: data.userid,
    //       name: "user_id",
    //       label: data.user?.name,
    //     });
    //   });
    // }
    // setUser(option);
  };

  const getEmployees = async (id) => {
    const response = await getData(`/masters/allemployees`);
    if (response?.success) {
      setUser(await Select2Data(response?.data, "user_id"));
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    getDataAll();
  }, [reset, show, show1]);

  const handleClose3 = () => setShow3(0);
  const handleShow3 = (id) => {
    setShow3(id);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const [activeButton, setActiveButton] = useState("Present");
  const [uploadModalShow, setUploadModalShow] = useState(false);

  console.log("activeButton", activeButton);

  const DemoDownload = async () => {
    await getDownloadDataExcel(
      "/hrms-masters/attendance/download",
      null,
      "Attendance Bulk Demo"
    );
  };
  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={6} xl={4} lg={12} md={12} sm={12} xs={12}>
            <p className="main-table-head">Attendance </p>
          </Col>
          <Col xxl={6} xl={8} lg={12} md={12} sm={12} xs={12} className="mb-3">
            <div className="add d-md-flex justify-content-lg-end">
              <Button
                onClick={() => DemoDownload()}
                className="custom-apply-leaves-btn me-2"
              >
                Download Sheet
                <span className="icon-container">
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </Button>
              <Button
                className="custom-apply-leaves-btn me-2"
                onClick={() => setUploadModalShow(true)}
              >
                Upload Sheet
                <span className="icon-container">
                  <FontAwesomeIcon icon={faRectangleList} />
                </span>
              </Button>
              {/* <Dropdown>
                <Dropdown.Toggle
                  className="custom-apply-leaves-btn"
                  id="dropdown-basic"
                >
                  Column Selection
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-0">Sr.No</Dropdown.Item>
                  <Dropdown.Item href="#/action-1">Employee Id</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Name</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Designation</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">Employee Type</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">Clock In Time</Dropdown.Item>
                  <Dropdown.Item href="#/action-5">Status</Dropdown.Item>
                  <Dropdown.Item href="#/action-6">Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </Col>
          {/* <AddModal
                        show={addmodalShow}
                        onHide={() => setAddModalShow(false)}
                    /> */}
        </Row>

        <div className="card-section">
          <div className="row">
            {/* Total Present Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/attendance-cards/card1.png"
                    }
                    alt="Card 1"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Total <br />
                    Present
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.total_present}</p>
                </div>
              </div>
            </div>

            {/* Total Absent Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/attendance-cards/card2.png"
                    }
                    alt="Card 2"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Total <br />
                    Absent
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.total_absent}</p>
                </div>
              </div>
            </div>

            {/* Late Marks Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/attendance-cards/card3.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Late <br />
                    Marks
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.total_late}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="red-bggg mb-3">
          <Row className="align-items-center">
            <Col
              xxl={10}
              xl={9}
              lg={8}
              md={10}
              sm={9}
              xs={12}
              className="text-lg-end mt-2 mt-md-0"
            ></Col>
          </Row>

          <div className="col-lg-12  col-md-12 col-12 justify-content-end">
            <Row>
              <Col xl={3} lg={4} className="mb-xl-0 mb-3">
                <Select
                  placeholder="Employee"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchuser(e);
                  }}
                  value={searchuser}
                  options={user}
                />
              </Col>
              <Col xl={3} lg={4} className="mb-xl-0 mb-3">
                <Select
                  placeholder="Month"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchmonth(e);
                  }}
                  value={searchmonth}
                  options={months}
                />
              </Col>
              <Col xl={3} lg={4} className="mb-xl-0 mb-3">
                <Select
                  placeholder="Year"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchyears(e);
                  }}
                  value={searchyears}
                  options={years}
                />
              </Col>
              <Col xl={3} lg={4} className="mb-xl-0 mb-3">
                <button
                  type="button"
                  onClick={getDataAll}
                  className="btn btn-search me-3"
                >
                  {/* <img src={search1} className="search" alt="" /> */}
                  Search
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setSearchuser("");
                    setSearchmonth("");
                    setSearchyears("");
                    setReset(!reset);
                  }}
                  className="btn btn-reset reset-btnn"
                >
                  Reset
                </button>
              </Col>
            </Row>

            <div className="num me-2"></div>
          </div>
        </div>

        <div className="data table-responsive attedance">
          <Table striped bordered hover responsive center>
            <thead>
              <tr>
                {<th className="tax-name">Employee</th>}
                {days?.map((day, index) => (
                  <th className="sr" key={index}>
                    {day.date}
                    <br />
                    {day.week}
                  </th>
                ))}
                <th className="sr">Total</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              {users?.map((student, studentIndex) => (
                <tr key={studentIndex}>
                  <td>{student.name}</td>
                  {student?.attendance?.map((data, dayIndex) => (
                    <td key={dayIndex}>
                      {data.isPresent ? (
                        <Link>
                          <div onClick={() => handleShow3(student?.id)}>
                            <OverlayTrigger
                              className="tooltip-text"
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  <p>
                                    <span>{student?.name}</span>
                                  </p>
                                  <p>
                                    Status:-{" "}
                                    <span>
                                      {" "}
                                      {data.isPresent ? "Present " : "Absent "}
                                    </span>
                                  </p>
                                  <p>
                                    Check in :- <span>{data?.clock_in}</span>
                                  </p>
                                  <p>
                                    Check Out:- <span>{data?.clock_out}</span>
                                  </p>
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                className="text-success"
                                icon="fa-solid fa-check"
                              />
                            </OverlayTrigger>
                          </div>
                        </Link>
                      ) : (
                        <OverlayTrigger
                          className="tooltip-text"
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              <p>
                                <span>{student?.name}</span>
                              </p>
                              <p>
                                Status:-{" "}
                                <span>
                                  {" "}
                                  {data.isPresent ? "Present " : "Absent "}
                                </span>
                              </p>
                              {/* <p>
                                Check in :- <span>12.02</span>
                              </p>
                              <p>
                                Check Out:- <span>12.02</span>
                              </p> */}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            className="text-danger"
                            icon="fa-solid fa-xmark"
                          />
                        </OverlayTrigger>
                      )}
                    </td>
                  ))}
                  <td>{student.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {/* <EditModal
                show={editmodalShow}
                onHide={() => setEditModalShow(false)}
            />
            <DeleteModal
                show={deletemodalShow}
                onHide={() => setDeleteModalShow(false)}
            /> */}
      <UploadModal
        show={uploadModalShow}
        onHide={() => {
          setUploadModalShow(false);
          getDataAll();
        }}
      />
    </section>
  );
};

export default Attendance;
