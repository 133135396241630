import React from 'react'
import { Col, Form, Row, } from 'react-bootstrap'
import '../AddEmployee.css'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const PersonalDetails = ({ getValues, register, classNames, errors, watch, IMG_URL, disabled, setValue, control, Controller }) => {
    const checkValues = [
        { value: "Male", name: "gender", label: "Male" },
        { value: "Female", name: "gender", label: "Female" },
        { value: "Other", name: "gender", label: "Other" },
    ]
    console.log("IMGURL", IMG_URL);
    const imageFile = watch("image");

    console.log("getVlaue", watch("personaldata.gender"));

    return (
        <section >
            <div className='below-formmmmm'>
                {/* <Form> */}
                <Row>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Emp Name</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="text"
                                name={`userdata.name`}
                                placeholder="Emp Name"
                                className={classNames("", {
                                    "is-invalid": errors?.userdata?.name,
                                })}
                                {...register(`userdata.name`, {
                                    required: "Emp Name is required",
                                    pattern: {
                                        value: /^[A-Za-z\s]{1,100}$/, // Regex to allow only letters and spaces (and limit to 100 characters)
                                        message: "Name must only contain letters and spaces, and be no longer than 100 characters", // Custom error message
                                    },
                                })}
                                onKeyDown={(e) => {
                                    // Allow: backspace, tab, enter, escape, space, and letters (A-Z, a-z)
                                    if (
                                        e.key === "Backspace" ||
                                        e.key === "Tab" ||
                                        e.key === "Enter" ||
                                        e.key === "Escape" ||
                                        e.key === " " ||
                                        /^[A-Za-z]$/.test(e.key) ||
                                        e.key === "ArrowLeft" ||
                                        e.key === "ArrowRight" ||
                                        e.key === "Delete" ||
                                        e.key === "ArrowUp" ||
                                        e.key === "ArrowDown"
                                    ) {
                                        const currentLength = e.target.value.length;
                                        if (currentLength >= 100) {
                                            if (
                                                e.key === "Backspace" ||
                                                /^[A-Za-z]$/.test(e.key) ||
                                                e.key === "ArrowLeft" ||
                                                e.key === "ArrowRight" ||
                                                e.key === "Delete" ||
                                                e.key === "ArrowUp" ||
                                                e.key === "ArrowDown"
                                            ) {
                                                return;
                                            }
                                            e.preventDefault();
                                        }
                                        return;
                                    } else {
                                        e.preventDefault();
                                    }
                                }}
                                maxLength={100}
                            />
                            {errors?.userdata?.name && (
                                <span className="text-danger">
                                    {errors?.userdata?.name?.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Date of birth</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="date"
                                max={(() => {
                                    const today = new Date();
                                    const eighteenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 18));
                                    return eighteenYearsAgo.toISOString().split("T")[0]; // Formats as YYYY-MM-DD
                                })()}
                                name={`personaldata.dob`}
                                placeholder="DOB"

                                className={classNames("", {
                                    "is-invalid": errors?.personaldata?.dob,
                                })}
                                {...register(`personaldata.dob`, {
                                    required: "DOB is required",
                                })}
                            />

                            {errors?.personaldata?.dob && (
                                <span className="text-danger">
                                    {errors?.personaldata?.dob.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                placeholder="Employee Age"
                                value={watch('personaldata.dob') ? Math.floor((new Date() - new Date(watch('personaldata.dob'))) / (1000 * 60 * 60 * 24 * 365.25)) : ""}
                            />
                            {errors?.userdata?.name && (
                                <span className="text-danger">
                                    {errors?.userdata?.name?.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Gender</Form.Label>
                            <Controller
                                name={`personaldata.gender`}
                                {...register(`personaldata.gender`, {
                                    required: "Select Gender",
                                })}
                                control={control}
                                rules={{
                                    required: "Select Gender",
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: errors?.personaldata?.gender ? "red" : baseStyles.borderColor,
                                            }),
                                        }}
                                        // {...field} 
                                        options={checkValues}
                                        onChange={(selectedValue) => {
                                            onChange(selectedValue);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        ref={ref}
                                        isDisabled={disabled}
                                    />
                                )}
                            />

                            {errors?.personaldata?.gender && (
                                <span className="text-danger">
                                    {errors?.personaldata?.gender.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Id</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="text"
                                name={`personaldata.personal_email`}
                                placeholder="Email"
                                className={classNames("", {
                                    "is-invalid": errors?.personaldata?.personal_email,
                                })}
                                {...register(`personaldata.personal_email`, {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                        message: "Invalid Email address",
                                    },
                                    validate: (value) => {
                                        // Extract the domain and TLD
                                        const domainPattern = /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/;
                                        const match = value.match(domainPattern);
                                        if (match) {
                                            const domainParts = match[1].split('.');
                                            const tld = match[2];

                                            // Ensure the domain and TLD are not the same
                                            if (domainParts[domainParts.length - 1] === tld) {
                                                return "Domain and top-level domain must be different";
                                            }
                                        }
                                        return true;
                                    }
                                })}

                            />
                            {errors?.personaldata?.personal_email && (
                                <span className="text-danger">
                                    {errors?.personaldata?.personal_email.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Mobile No</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="text"
                                name={`userdata.mobile_number`}

                                placeholder="Mobile Number"
                                className={classNames("", {
                                    "is-invalid": errors?.userdata?.mobile_number,
                                })}
                                {...register(`userdata.mobile_number`, {
                                    required: "Mobile Number is required",
                                    validate: (value) => value.length === 10 || " Contact number must be exactly 10 digits",
                                })}
                                onKeyDown={(event) => {
                                    const { key } = event;
                                    const value = event.target.value;

                                    // Prevent non-digit characters except Backspace and Tab
                                    if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                        event.preventDefault();
                                    }

                                    // Prevent input if length exceeds 10 digits
                                    if (value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {errors?.userdata?.mobile_number && (
                                <span className="text-danger">
                                    {errors?.userdata?.mobile_number.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>



                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Blood Group</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="text"

                                name={`personaldata.blood_group`}
                                placeholder="Blood Group"
                                className={classNames("", {
                                    "is-invalid": errors?.personaldata?.blood_group,
                                })}
                                {...register(`personaldata.blood_group`, {
                                    required: "Blood Group is required",
                                    pattern: {
                                        value: /^(A|B|AB|O)[+-]$/,
                                        message: "Invalid blood group format. Use A+, A-, B+, B-, O+, O-, AB+, or AB-.",
                                    },
                                })}
                            />
                            {errors?.personaldata?.blood_group && (
                                <span className="text-danger">
                                    {errors?.personaldata?.blood_group.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Guardian Name</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="text"
                                name={`personaldata.guardian_name`}
                                placeholder="Guardian Name"
                                className={classNames("", {
                                    "is-invalid": errors?.personaldata?.guardian_name,
                                })}
                                {...register(`personaldata.guardian_name`, {
                                    required: "Guardian Name is required",
                                    pattern: {
                                        value: /^[A-Za-z\s]{1,100}$/, // Regex to allow only letters and spaces (and limit to 100 characters)
                                        message: "Name must only contain letters and spaces, and be no longer than 100 characters", // Custom error message
                                    },
                                })}
                                onKeyDown={(e) => {
                                    // Allow: backspace, tab, enter, escape, space, and letters (A-Z, a-z)
                                    if (
                                        e.key === "Backspace" ||
                                        e.key === "Tab" ||
                                        e.key === "Enter" ||
                                        e.key === "Escape" ||
                                        e.key === " " ||
                                        /^[A-Za-z]$/.test(e.key) ||
                                        e.key === "ArrowLeft" ||
                                        e.key === "ArrowRight" ||
                                        e.key === "Delete" ||
                                        e.key === "ArrowUp" ||
                                        e.key === "ArrowDown"
                                    ) {
                                        const currentLength = e.target.value.length;
                                        if (currentLength >= 100) {
                                            if (
                                                e.key === "Backspace" ||
                                                /^[A-Za-z]$/.test(e.key) ||
                                                e.key === "ArrowLeft" ||
                                                e.key === "ArrowRight" ||
                                                e.key === "Delete" ||
                                                e.key === "ArrowUp" ||
                                                e.key === "ArrowDown"
                                            ) {
                                                return;
                                            }
                                            e.preventDefault();
                                        }
                                        return;
                                    } else {
                                        e.preventDefault();
                                    }
                                }}
                                maxLength={100}
                            />
                            {errors?.personaldata?.guardian_name && (
                                <span className="text-danger">
                                    {errors?.personaldata?.guardian_name.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Guardian Mobile No</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                type="text"
                                name={`personaldata.guardian_contact_no`}
                                placeholder="Guardian mobile no"
                                className={classNames("", {
                                    "is-invalid": errors?.personaldata?.guardian_contact_no,
                                })}
                                {...register(`personaldata.guardian_contact_no`, {
                                    required: "Guardian mobile no is required",
                                    validate: (value) => value.length === 10 || "Manager Contact number must be exactly 10 digits",
                                })}
                                onKeyDown={(event) => {
                                    const { key } = event;
                                    const value = event.target.value;

                                    // Prevent non-digit characters except Backspace and Tab
                                    if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                        event.preventDefault();
                                    }

                                    // Prevent input if length exceeds 10 digits
                                    if (value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {errors?.personaldata?.guardian_contact_no && (
                                <span className="text-danger">
                                    {errors?.personaldata?.guardian_contact_no.message}
                                </span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Add Employee Photo</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                className={classNames("", {
                                    "is-invalid": errors?.image,
                                })}
                                type="file"

                                {...register("image", {
                                    // required: "images is required",
                                })}

                                accept="image/*"
                            />
                            {errors?.image && (
                                <span className="text-danger">
                                    {errors?.image.message}
                                </span>
                            )}

                            {/* <div className="file-field-holder">
                                <Form.Control
                                    // type="file"
                                    placeholder="Enter Name"
                                />
                                <div className="upload-btn-holder">
                                    <FontAwesomeIcon
                                        className="upload-icon"
                                        icon={faUpload}
                                    />
                                </div>
                            </div> */}
                        </Form.Group>

                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <div className='text-end'>
                            {typeof getValues("image") == "string" ? (
                                <div
                                    className="image-preview-container mt-3"
                                    style={{ marginLeft: "110px" }}
                                >
                                    <img
                                        src={IMG_URL + getValues("image")}
                                        alt="Preview"
                                        className="image-preview"
                                        style={{ width: "50px", height: "50px" }}
                                    />
                                </div>
                            ) : (
                                imageFile &&
                                imageFile?.length > 0 && (
                                    <div
                                        className="image-preview-container mt-3"
                                        style={{ marginLeft: "110px" }}
                                    >
                                        <img
                                            // src={URL.createObjectURL(getValues("image")[0])}
                                            src={URL?.createObjectURL(imageFile[0])}
                                            alt="Preview"
                                            className="image-preview"
                                            style={{ width: "50px", height: "50px" }}
                                        />
                                    </div>
                                )
                            )}

                        </div>
                    </Col>
                </Row>
                {/* </Form> */}
            </div>
        </section>
    )
}

export default PersonalDetails