import React, { useState, useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import HrmsDashboard from "../Hrms-Setup-Content/Hrms-Dashboard/HrmsDashboard";
import Department from "../Hrms-Setup-Content/Department/Department";
import Designation from "../Hrms-Setup-Content/Designation/Designation";
import Skills from "../Hrms-Setup-Content/Skills/Skills";
import LeaveType from "../Hrms-Setup-Content/LeaveType/LeaveType";
import LeaveMaster from "../Hrms-Setup-Content/Leave/Leave";
import EmployeeType from "../Hrms-Setup-Content/EmployeeType/EmployeeType";
import EmploymentType from "../Hrms-Setup-Content/EmploymentType/EmploymentType";
import Assets from "../Hrms-Setup-Content/Assets/Assets";
import Prefix from "../Hrms-Setup-Content/Prefix/Prefix";
import Shifts from "../Hrms-Setup-Content/Shifts/Shifts";
import Qualification from "../Hrms-Setup-Content/Qualification/Qualification";
import University from "../Hrms-Setup-Content/University/University";
import Course from "../Hrms-Setup-Content/Course/Course";
import Employee from "../Hrms-Dashboard-Content/Employee/Employee";
import Leave from "../Hrms-Dashboard-Content/Leave/Leave";
// import Leave from "../Hrms-Setup-Content/Leave/Leave";
import Attendance from "../Hrms-Dashboard-Content/Attendance/Attendance";
import PaymentSlip from "../Hrms-Dashboard-Content/Payment-Slip/PaymentSlip";
import AddEmployee from "../Hrms-Dashboard-Content/Employee/EmployeeModal/AddEmployee";
import DashboardAssets from "../Hrms-Dashboard-Content/Assets/DashboardAssets";
import DashBoardShifts from "../Hrms-Dashboard-Content/Shifts/DashBoardShifts";
import EditEmployee from "../Hrms-Dashboard-Content/Employee/EmployeeModal/EditEmployee";
import ViewEmployee from "../Hrms-Dashboard-Content/Employee/EmployeeModal/ViewEmployee";
import HrmsProfile from "../Hrms-Profile/HrmsProfile";
import CalendarHrms from "../Hrms-Setup-Content/Calendar-Hrms/CalendarHrms";
import LeaveSetup from "../Hrms-Setup-Content/LeaveSetup/LeaveSetup";
import { Context } from "../../../utils/context";
import { IDS } from "../../../utils/common";
import PageNotFound from "../../PageNotFound/PageNotFound";
import DeviceControl from "../Hrms-Setup-Content/DeviceControl/DeviceControl";

const Hroutes = () => {

  const { isAllow } = useContext(Context);
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/hrms-dashboard",
        element: <HrmsDashboard />,
        status: true,
      },
      {
        path: "/hrms-prefix",
        element: <Prefix />,
        status: isAllow?.includes(IDS.CompanyPrefix.List),
      },
      {
        path: "/hrms-department",
        element: <Department />,
        status: isAllow?.includes(IDS.Department.List),
      },
      {
        path: "/hrms-designation",
        element: <Designation />,
        status: isAllow?.includes(IDS.Designation.List),
      },
      {
        path: "/hrms-skills",
        element: <Skills />,
        status: isAllow?.includes(IDS.Skills.List),
      },
      {
        path: "/hrms-leave-type",
        element: <LeaveType />,
        status: isAllow?.includes(IDS.LeaveType.List),
      },
      {
        path: "/hrms-leave-setup",
        element: <LeaveSetup />,
        status: isAllow?.includes(IDS.LeaveSetup.List),
      },
      {
        path: "/hrms-leave",
        element: <LeaveMaster />,
        status: isAllow?.includes(IDS.Leave.List),
      },
      {
        path: "/hrms-employee-type",
        element: <EmployeeType />,
        status: isAllow?.includes(IDS.EmployeeType.List),
      },
      {
        path: "/hrms-employment-type",
        element: <EmploymentType />,
        status: isAllow?.includes(IDS.EmploymentType.List),
      },
      {
        path: "/hrms-setup-assets",
        element: <Assets />,
        status: isAllow?.includes(IDS.Asset.List),
      },
      {
        path: "/hrms-setup-shifts",
        element: <Shifts />,
        status: isAllow?.includes(IDS.Shift.List),
      },

      {
        path: "/hrms-setup-qualification",
        element: <Qualification />,
        status: isAllow?.includes(IDS.Qualification.List),
      },
      {
        path: "/hrms-setup-university",
        element: <University />,
        status: isAllow?.includes(IDS.University.List),
      },
      {
        path: "/hrms-setup-course",
        element: <Course />,
        status: isAllow?.includes(IDS.Course.List),
      },
      {
        path: "/hrms-employee",
        element: <Employee />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/hrms-leave",
        element: <Leave />,
        status: isAllow?.includes(IDS.Leave.List),
      },
      {
        path: "/hrms-calendar",
        element: <CalendarHrms />,
        status: isAllow?.includes(IDS.Calender.List),
      },
      {
        path: "/hrms-attendance",
        element: <Attendance />,
        status: isAllow?.includes(IDS.Attendance.List),
      },
      {
        path: "/hrms-payment-slip",
        element: <PaymentSlip />,
        status: true,
      },
      {
        path: "/hrms-assets",
        element: <DashboardAssets />,
        status: isAllow?.includes(IDS.AssignAssetsList.List),
      },
      {
        path: "/hrms-shift",
        element: <DashBoardShifts />,
        status: isAllow?.includes(IDS.AssignShiftList.List),
      },
      {
        path: "/add-employee",
        element: <AddEmployee />,
        status: isAllow?.includes(IDS.User.Add),
      },
      {
        path: "/edit-employee/:id",
        element: <EditEmployee />,
        status: isAllow?.includes(IDS.User.Edit),
      },
      {
        path: "/view-employee/:id",
        element: <ViewEmployee />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/hrms-profile",
        element: <HrmsProfile />,
        status: true,
      },
      {
        path: "/hrms-device-control",
        element: <DeviceControl />,
        status: true,
      },
    ]);
  }, [isAllow]);

  return (
    // <Routes>
    //   {/* HRMS Setup Content */}
    //   <Route path="/hrms-dashboard" element={<HrmsDashboard />} />
    //   <Route path="/hrms-department" element={<Department />} />
    //   <Route path="/hrms-designation" element={<Designation />} />
    //   <Route path="/hrms-skills" element={<Skills />} />
    //   <Route path="/hrms-leave-type" element={<LeaveType />} />
    //   <Route path="/hrms-leave-setup" element={<LeaveSetup />} />
    //   <Route path="/hrms-leave" element={<LeaveMaster />} />
    //   <Route path="/hrms-employee-type" element={<EmployeeType />} />
    //   <Route path="/hrms-setup-assets" element={<Assets />} />
    //   <Route path="/hrms-setup-shifts" element={<Shifts />} />
    //   <Route path="/hrms-setup-qualification" element={<Qualification />} />
    //   <Route path="/hrms-setup-university" element={<University />} />
    //   <Route path="/hrms-setup-course" element={<Course />} />

    //   {/* HRMS dashboard Content */}
    //   <Route path="/hrms-employee" element={<Employee />} />
    //   <Route path="/hrms-leave" element={<Leave />} />
    //   <Route path="/hrms-calendar" element={<CalendarHrms />} />
    //   <Route path="/hrms-attendance" element={<Attendance />} />
    //   <Route path="/hrms-payment-slip" element={<PaymentSlip />} />
    //   <Route path="/hrms-assets" element={<DashboardAssets />} />
    //   <Route path="/hrms-shift" element={<DashBoardShifts />} />

    //   {/* Employee */}
    //   <Route path="/add-employee" element={<AddEmployee />} />
    //   <Route path="/edit-employee/:id" element={<EditEmployee />} />
    //   <Route path="/view-employee/:id" element={<ViewEmployee />} />

    //   {/* Profile */}
    //   <Route path="/hrms-profile" element={<HrmsProfile />} />
    // </Routes>

    <Routes>
      {urls?.map(
        (url) =>
          url.status && <Route path={url.path} element={url.element} />
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>


  );
};

export default Hroutes;
