import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import { Col, Form, Nav, Row, Tab } from 'react-bootstrap'
import { Watch } from 'react-bootstrap-icons';
import Select from 'react-select';

const ResidentialAddress = ({
    getValues,
    register,
    classNames,
    errors,
    Controller,
    control,
    countries,
    currentStates,
    currenCities,
    currentPincodes,
    permanentStates,
    permanentCities,
    permanentPincodes,
    GetAllState,
    GetAllCities,
    GetAllPincodes,
    setValue,
    disabled,
    watch,
    reset,
    isEdit
}) => {

    const [offFields, setOffFields] = useState(false);

    const onTick = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setOffFields(true);
            setValue("permanentadd.address", getValues("currentadd.address"));
            setValue("permanentadd.country_id", getValues("currentadd.country_id"));
            setValue("permanentadd.state_id", getValues("currentadd.state_id"));
            setValue("permanentadd.city_id", getValues("currentadd.city_id"));
            setValue("permanentadd.pincode_id", getValues("currentadd.pincode_id"));
        } else {
            setOffFields(false);
            setValue("permanentadd.address", "");
            setValue("permanentadd.country_id", "");
            setValue("permanentadd.state_id", "");
            setValue("permanentadd.city_id", "");
            setValue("permanentadd.pincode_id", "");
        }
    };

    // const checkSame = () => {
    //     if (
    //         watch("permanentadd.address") == watch("currentadd.address") &&
    //         watch("permanentadd.country_id.value") == watch("currentadd.country_id.value") &&
    //         watch("permanentadd.state_id.value") == watch("currentadd.state_id.value") &&
    //         watch("permanentadd.city_id.value") == watch("currentadd.city_id.value") &&
    //         watch("permanentadd.pincode_id.value") == watch("currentadd.pincode_id.value")
    //     ) {
    //         setOffFields(true);
    //         return;
    //     }
    //     setOffFields(false);
    // }
    // useEffect(() => {
    //     if (isEdit) {
    //         checkSame();
    //     }
    // }, [])

    return (
        <div>
            <div className='below-formmmmm'>
                <Form>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Current address</Nav.Link>
                                    {(errors?.currentadd?.country_id || errors?.currentadd?.state_id || errors?.currentadd?.city_id ||
                                        errors?.currentadd?.address || errors?.currentadd?.pincode_id) && (
                                            <span className="text-danger ms-5">
                                                {"Fill All Fields 👇"}
                                            </span>
                                        )}
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        Permanent address


                                    </Nav.Link>

                                    {(errors?.permanentadd?.country_id || errors?.permanentadd?.state_id || errors?.permanentadd?.city_id ||
                                        errors?.permanentadd?.address || errors?.permanentadd?.pincode_id) && (
                                            <span className="text-danger ms-5">
                                                {"Fill All Fields 👇"}
                                            </span>
                                        )}
                                </Nav.Item>

                            </Nav>
                            <Tab.Content className='mt-4'>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Current address</Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    type="text"
                                                    name={`currentadd.address`}
                                                    placeholder="Current address"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.currentadd?.address,
                                                    })}
                                                    {...register(`currentadd.address`, {
                                                        required: "Address is required",
                                                    })}
                                                    onKeyDown={() => setOffFields(false)}

                                                />
                                                {errors?.currentadd?.address && (
                                                    <div className="invalid-feedback">
                                                        {errors?.currentadd?.address?.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Country</Form.Label>
                                                <Controller
                                                    name={`currentadd.country_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select Country",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.country_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field} 
                                                            options={countries}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllState(selectedValue.value);
                                                                setValue("currentadd.state_id", "");
                                                                setValue("currentadd.city_id", "");
                                                                setValue("currentadd.pincode_id", "");
                                                                setOffFields(false)
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.country_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.country_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>State</Form.Label>
                                                <Controller
                                                    name={`currentadd.state_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select State",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.state_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={currentStates}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllCities(selectedValue.value);
                                                                setValue("currentadd.city_id", "");
                                                                setValue("currentadd.pincode_id", "");
                                                                setOffFields(false)
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.state_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.state_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>City</Form.Label>
                                                <Controller
                                                    name={`currentadd.city_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.city_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={currenCities}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllPincodes(selectedValue.value);
                                                                setValue("currentadd.pincode_id", "");
                                                                setOffFields(false)
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.city_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.city_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Pincode</Form.Label>
                                                <Controller
                                                    name={`currentadd.pincode_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.pincode_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={currentPincodes}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                setOffFields(false)
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.pincode_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.pincode_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Form.Label>Duration Of stay</Form.Label>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>From</Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    max={watch('currentadd.to_date') ? new Date(watch('currentadd.to_date')).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                                                    type="date"
                                                    name="currentadd.from_date"
                                                    placeholder="From Date"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.currentadd?.from_date,
                                                    })}
                                                    {...register('currentadd.from_date', {
                                                        required: "Date required",
                                                        validate: {
                                                            // Custom validation for min date (ensure from_date is before to_date)
                                                            lessThanToday: (value) => {
                                                                const today = new Date().toISOString().split("T")[0];
                                                                if (new Date(value) > new Date(today)) {
                                                                    return "From Date cannot be in the future."; // Error message if the date is in the future
                                                                }
                                                                return true; // Validation passed
                                                            },
                                                            lessThanToDate: (value) => {
                                                                const toDate = watch('currentadd.to_date');
                                                                if (toDate && new Date(value) > new Date(toDate)) {
                                                                    return "From Date must be before or equal to To Date."; // Error message if from_date is after to_date
                                                                }
                                                                return true; // Validation passed
                                                            }
                                                        }

                                                    })}
                                                />
                                                {errors?.currentadd?.from_date && (
                                                    <div className="invalid-feedback">
                                                        {errors.currentadd.from_date.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
                                                <Form.Label>To</Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    type="date"
                                                    min={watch('currentadd.from_date')}
                                                    name="currentadd.to_date"
                                                    placeholder="To Date"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.currentadd?.to_date,
                                                    })}
                                                    {...register('currentadd.to_date', {
                                                        required: "Date required",
                                                        validate: {
                                                            // Custom validation to ensure to_date is greater than from_date
                                                            greaterThanFromDate: (value) => {
                                                                const fromDate = watch('currentadd.from_date');
                                                                if (fromDate && new Date(value) <= new Date(fromDate)) {
                                                                    return "To Date must be greater than From Date."; // Error message if to_date is not greater than from_date
                                                                }
                                                                return true; // Validation passed
                                                            },
                                                        }
                                                    })}
                                                />
                                                {errors?.currentadd?.to_date && (
                                                    <div className="invalid-feedback">
                                                        {errors.currentadd.to_date.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    {!disabled &&
                                        <>
                                            <Form.Label> Same As Current Address ?</Form.Label>
                                            <input className='ms-3'
                                                type="checkbox"
                                                onChange={onTick}
                                                checked={offFields}
                                            />
                                        </>
                                    }
                                    <Row>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Permanent address</Form.Label>
                                                <Form.Control
                                                    disabled={disabled || offFields}
                                                    type="text"
                                                    name={`permanentadd.address`}
                                                    placeholder="Current address"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.permanentadd?.address,
                                                    })}
                                                    {...register(`permanentadd.address`, {
                                                        required: "Address is required",
                                                    })}
                                                />
                                                {errors?.permanentadd?.address && (
                                                    <div className="invalid-feedback">
                                                        {errors?.permanentadd?.address?.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Country</Form.Label>
                                                <Controller
                                                    name={`permanentadd.country_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select Country",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.country_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field} 
                                                            options={countries}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllState(selectedValue.value, true);
                                                                setValue("permanentadd.state_id", "");
                                                                setValue("permanentadd.city_id", "");
                                                                setValue("permanentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled || offFields}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.country_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.country_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>State</Form.Label>
                                                <Controller
                                                    name={`permanentadd.state_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select State",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.state_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={permanentStates}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllCities(selectedValue.value, true);
                                                                setValue("permanentadd.city_id", "");
                                                                setValue("permanentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled || offFields}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.state_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.state_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>City</Form.Label>
                                                <Controller
                                                    name={`permanentadd.city_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.city_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={permanentCities}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllPincodes(selectedValue.value, true);
                                                                setValue("permanentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled || offFields}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.city_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.city_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Pincode</Form.Label>
                                                <Controller
                                                    name={`permanentadd.pincode_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.pincode_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={permanentPincodes}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled || offFields}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.pincode_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.pincode_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Tab.Container>

                </Form>
            </div>
        </div>
    )
}

export default ResidentialAddress