import { Modal } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import "../../../../Admin/Tabels/Tabels.css";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../common/ModalSave";
import { CancelButton } from "../../../../common/Button";
import EditLottieModal from "../../../commonLottie/EditLottieModal";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";

library.add(fas);

const EditModal = (props) => {
  const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });
  const id = props.show;
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const GetEditData = async () => {
    const response = await getData(`/hrms-masters/leave_type/${id}`);
    reset(response?.data);
  };

  useEffect(() => {
    if (id) {
      GetEditData();
    }
  }, [id]);

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("name", data?.name);
      sendData.append("color", data?.color);

      if (!id) {
        throw new Error("ID is missing");
      }

      const response = await postData(
        `/hrms-masters/leave_type/${id}`,
        sendData
      );
      console.log(response);

      if (response?.success) {
        setShowLottie({ show: true, msg: response?.message, success: response?.success });
      } else {
        setShowLottie({ show: true, msg: response?.message, success: response?.success });
      }
      setTimeout(() => {
        setShowLottie({ show: false, msg: "", success: false });
        props.handleClose();
      }, 3000);
    } catch (error) {
      console.log("Error submitting form: ", error);
      setShowLottie({ show: true, msg: "Error", success: false });
    }
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
        onHide={props.handleClose}
      >
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Edit Leave Type</h4>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={8} md={10} className="mx-auto Add-content">
                      <div className="main-form-section mt-5">
                        <Form.Label>Leave Type</Form.Label>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Leave Type"
                              className={classNames("", {
                                "is-invalid": errors?.name,
                              })}
                              {...register("name", {
                                required: "Leave Type is required",
                                pattern: {
                                  value: /^[A-Za-z\s]{1,100}$/, // Regex to allow only letters and spaces (and limit to 100 characters)
                                  message: "Name must only contain letters and spaces, and be no longer than 100 characters", // Custom error message
                                },
                              })}
                              onKeyDown={(e) => {
                                // Allow: backspace, tab, enter, escape, space, and letters (A-Z, a-z)
                                if (
                                  e.key === "Backspace" ||
                                  e.key === "Tab" ||
                                  e.key === "Enter" ||
                                  e.key === "Escape" ||
                                  e.key === " " ||
                                  /^[A-Za-z]$/.test(e.key) ||
                                  e.key === "ArrowLeft" ||
                                  e.key === "ArrowRight" ||
                                  e.key === "Delete" ||
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  const currentLength = e.target.value.length;
                                  if (currentLength >= 100) {
                                    if (
                                      e.key === "Backspace" ||
                                      /^[A-Za-z]$/.test(e.key) ||
                                      e.key === "ArrowLeft" ||
                                      e.key === "ArrowRight" ||
                                      e.key === "Delete" ||
                                      e.key === "ArrowUp" ||
                                      e.key === "ArrowDown"
                                    ) {
                                      return;
                                    }
                                    e.preventDefault();
                                  }
                                  return;
                                } else {
                                  e.preventDefault();
                                }
                              }}
                              maxLength={100}
                            />
                          </InputGroup>
                          {errors.name && (
                            <span className="text-danger">
                              {errors.name.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>

                      <div className="main-form-section mt-5">
                        <Form.Label>Color</Form.Label>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <InputGroup>
                            <Form.Control
                              type="color"
                              name="color"
                              className={classNames("", {
                                "is-invalid": errors?.color,
                              })}
                              {...register("color", {
                                required: "Color is required",
                              })}
                            />
                          </InputGroup>
                          {errors.color && (
                            <span className="text-danger">
                              {errors.color.message}
                            </span>
                          )}
                        </Form.Group>
                      </div>

                      <div className="d-flex justify-content-center mt-5 pb-3">
                        <CancelButton
                          name="cancel"
                          handleClose={props.handleClose}
                        />
                        <button type="submit" className="save-button">
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <AddedLottieModal
        pageTitle="Designation"
        message={showLottie.msg}
        show={showLottie.show}
        success={showLottie.success}
        onHide={() => setShowLottie({ show: false, msg: "", success: false })}
      />
    </section>
  );
};

export default EditModal;
