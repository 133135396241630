import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../../utils/context";
import Pagination_Holder from "../../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";

const Leave = () => {
  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [deletemodalShow, setDeleteModalShow] = useState(false);

  const {
    postData,
    getData,
    Select2Data,
    Per_Page_Dropdown,
    editStatusData,
    deleteData,
  } = useContext(Context);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [totalPages, settotalPages] = useState();
  const [onPageChange, setonPageChange] = useState(1);

  const getDataAll = async () => {
    const response = await getData(
      `/hrms-masters/leave?page=${onPageChange}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    settotalPages(response?.data?.totalPages);
    // const newData = response?.data?.data;
    // if (newData) {
    //     const newIds = newData.map((d) => d?.id);
    //     setAllChecked(newIds);
    // }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, onPageChange]);

  const handlePageChange = (pageNumber) => {
    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const DeleteRecord = async (id) => {
    const response = await deleteData(`/hrms/employee/${id}`);
    if (response?.success) {
      setDeleteModalShow(true);
      getDataAll();
    }
  };

  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  const ChangeStatus = async (id, status) => {
    const response = await editStatusData(`/hrms-masters/leave/${id}`, {
      status: status,
    });

    // if (response.success) {
    //   setShowModal(true);
    //   setTimeout(() => {
    //     setShowModal(false);
    //   }, 1000);
    // }
    setChangeStatus(response);
  };


  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={8} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="main-table-head">Leave </p>
          </Col>
          <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className="add d-md-flex justify-content-lg-end">
              <Dropdown>
                {/* <Dropdown.Toggle className="custom-apply-leaves-btn" id="dropdown-basic">

                  Column Selection
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle> */}

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-0">Sr.No</Dropdown.Item>
                  <Dropdown.Item href="#/action-1">Employee Id</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Name</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Leave Type</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">Resaon</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">Duration</Dropdown.Item>
                  <Dropdown.Item href="#/action-5">Status</Dropdown.Item>
                  <Dropdown.Item href="#/action-6">Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          {/* <AddModal
                        show={addmodalShow}
                        onHide={() => setAddModalShow(false)}
                    /> */}
        </Row>

        <div className="card-section">
          <div className="row">
            {/* Total Present Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card1.png"
                    }
                    alt="Card 1"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Total Applied
                    <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.data?.total_leaves}</p>
                </div>
              </div>
            </div>

            {/* Total Absent Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card2.png"
                    }
                    alt="Card 2"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Approved <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.data?.approved_leaves}</p>
                </div>
              </div>
            </div>

            {/* Late Marks Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card3.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Rejected <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.data?.reject_leaves}</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card4.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Pending <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>{data?.data?.pending_leaves}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setperPage(e.target.value)}
                >
                  {option?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <label for="entries" className="ms-2">
                  Entries
                </label>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="sowing me-2">
                <p className="text-end showing-txttt mt-2">{`Showing ${Math.min(
                  (currentPage - 1) * perPage + 1
                )} to ${Math.min(
                  currentPage * perPage,
                  data?.data?.totalEntries
                )} of ${data?.data?.totalEntries} Entries`}</p>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Name Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      // Allow: backspace, tab, enter, escape, space, and letters (A-Z, a-z)
                      if (
                        e.key === "Backspace" ||
                        e.key === "Tab" ||
                        e.key === "Enter" ||
                        e.key === "Escape" ||
                        e.key === " " ||
                        /^[A-Za-z]$/.test(e.key) ||
                        e.key === "ArrowLeft" ||
                        e.key === "ArrowRight" ||
                        e.key === "Delete" ||
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown"
                      ) {
                        const currentLength = e.target.value.length;
                        if (currentLength >= 100) {
                          if (
                            e.key === "Backspace" ||
                            /^[A-Za-z]$/.test(e.key) ||
                            e.key === "ArrowLeft" ||
                            e.key === "ArrowRight" ||
                            e.key === "Delete" ||
                            e.key === "ArrowUp" ||
                            e.key === "ArrowDown"
                          ) {
                            return;
                          }
                          e.preventDefault();
                        }
                        return;
                      } else {
                        e.preventDefault();
                      }
                    }}
                    maxLength={100}
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={3} xl={6} lg={6} md={12} sm={6} xs={12}>
              <div>
                <button
                  type="button"
                  onClick={() => { setonPageChange(1); getDataAll(); }}
                  className="btn btn-search mb-3 me-2"
                >
                  Search
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setReset(!reset);
                  }}
                  className="btn reset-btnn mb-3"
                >
                  Reset
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Emp Id</th>
                <th>Name</th>
                <th>Leave Type</th>
                <th>Reason</th>
                <th>Duration</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              {data?.data?.data?.map((d, index) => {
                const paginatedIndex =
                  (onPageChange - 1) * perPage + index + 1;
                return (
                  <tr className="" key={index}>
                    <td>{paginatedIndex}.</td>
                    <td>{d?.user?.e_employment_detail?.employee_id}</td>
                    <td>{d?.user?.name}</td>
                    <td>{d?.leave_type?.name}</td>
                    <td>{d?.reason}</td>
                    <td>
                      {d?.start} {d?.end ? `To ${d?.end}` : ""}
                    </td>
                    <td>
                      <div>
                        <Form.Select
                          value={d?.status} // Uncomment if you want to control the selected value
                          onChange={(e) => ChangeStatus(d?.id, e.target.value)}
                          aria-label="Select "
                        >
                          {/* <option value="">Take Action</option> */}
                          <option value="Pending">Pending</option>
                          <option value="Approved">Approve</option>
                          <option value="Rejected">Reject</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data && data?.data?.data?.length > 0 ?
            <Pagination_Holder
              onPageChange={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            /> : <p className="no-datashow">Sorry, No Data Found</p>}
        </div>
      </div>
      {/* <EditModal
                show={editmodalShow}
                onHide={() => setEditModalShow(false)}
            />
            <DeleteModal
                show={deletemodalShow}
                onHide={() => setDeleteModalShow(false)}
            /> */}
    </section>
  );
};

export default Leave;
