import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../Admin/DashBoard/DashBoard";
import Sidebar from "../Admin/Sidebar/Sidebar";
import Header from "../Admin/Header/Header";
import AdvanceDashboard from "../Admin/DashBoard/AdvanceDashboard/AdvanceDashboard";
import PrivateDashboard from "../Admin/DashBoard/PrivateDashboard/PrivateDashboard";
import Order from "../Admin/DashBoard/Order/Order";
// import PrimarySkills from "./Components/Admin/Masters/PrimarySkills/Tables";
// import SecondarySkills from "./Components/Admin/Masters/SecondarySkills/Tables";
import Country from "../Admin/Masters/Country/Tables";
import State from "../Admin/Masters/State/Tables";
import City from "../Admin/Masters/City/Tables";
import Zone from "../Admin/Masters/Zone/Tables";
import Pincode from "../Admin/Masters/Pincode/Tables";
import Departments from "../Admin/Masters/Department/Tables";
import Education_List from "../Admin/Masters/Education_List/Tables";
import Order_Status from "../Admin/Masters/Order_Status/Tables";
import Payment_Status from "../Admin/Masters/Payment_Status/Tables";
import GSTlist from "../Admin/Masters/gstlist/Tables";
import Country_Code from "../Admin/Masters/CountryCode/Tables";
import Initial from "../Admin/Masters/Initial/Tables";
import Currency from "../Admin/Masters/Currency/Tables";
import ComplaintTypes from "../Admin/Masters/ComplaintTypes/Table";
import VisitType from "../Admin/Masters/VisitType/Table";
import ComplaintSource from "./Masters/ComplaintSource/Table";
import ComplaintPriorities from "../Admin/Masters/ComplaintPriorities/Table";
import ComplaintStatus from "../Admin/Masters/ComplaintStatus/Table";
import VisitRate from "../Admin/Masters/VisitRate/Table";
import Customer from "../Admin/Masters/Customer/Table";
import Chanel_name from "../Admin/TicketManagement/Chanel_name/Tables";
import Ticket_types from "../Admin/TicketManagement/Ticket_types/Tables";
import Ticket_status from "../Admin/TicketManagement/Ticket_status/Tables";
import Priorities from "../Admin/TicketManagement/Priorities/Tables";
import Ticket_generates from "../Admin/TicketManagement/Ticket_generates/Tables";
import Department from "../Admin/HRM/Department/Table";
import Designation from "../Admin/HRM/Designation/Table";
import Skills from "../Admin/HRM/skills/Table";
import LeaveType from "../Admin/HRM/LeaveType/Table";
import Leave from "../Admin/HRM/Leave/Tables";
import Holiday from "../Admin/HRM/Holiday/Table";
import AttendanceTable from "../Admin/HRM/AttendanceTable/AttendanceTable";
import RouteList from "../Admin/RouteManagement/RouteList/Table";
import ProductList from "../Admin/ProductManagement/ProductList/Table";
import EmployeeRole from "../Admin/EmployeeManagement/Role/Tables";
import EmployeeDetails from "../Admin/EmployeeManagement/EmployeeDetails/Tables";
import AllLeads from "../Admin/LeadManagement/Leads/Tables";
import LeadStatus from "../Admin/LeadManagement/Lead_Status/Tables";
import Document from "../Admin/LeadManagement/Document/Tables";
import LeadStatusDetails from "../Admin/LeadManagement/leadstatus_details/Table";
import Service from "../Admin/LeadManagement/Service/Table";
// Co Ordinator Management Manager
import CoOrdinatorManagementManager from "../Admin/Co_ordinator_management/Manager/Tables";
import CoOrdinatorManagementSalesCoOrdinater from "../Admin/Co_ordinator_management/SalesCoOrdinater/Tables";
import CoOrdinatorManagementSalesExecutive from "../Admin/Co_ordinator_management/SalesExecutive/Tables";

// App Set up

import AppSetup from "../Admin/AppSetup/Table";

import TaskCategory from "../Admin/ProjectManagement/Task_Category/Tables";
import ProjectCategory from "../Admin/ProjectManagement/Project_Category/Tables";
import DiscussionCategory from "../Admin/ProjectManagement/Discussion_Category/Tables";
// import ClientCategory from "../Admin/ProjectManagement/Client/Tables";
import Project from "./ProjectManagement/Project/Project";
import Addproject from "./ProjectManagement/Project/Addproject/Addproject";
import Project_template from "./ProjectManagement/Project/Project_template/Project_template";
import AddTemplate from "./ProjectManagement/Project/Project_template/Add/AddTemplate";
import Veiw_Details from "./ProjectManagement/Project/Veiw_Details/Veiw_Details";
import SearchTemplate from "./ProjectManagement/Project/Project_template/SearchTemplate/SearchTemplate";
import Project_Task from "./ProjectManagement/Project-task/Project_Task";
import Task_label from "./ProjectManagement/Project-task/Task-label/Task_label";
import Newtask from "./ProjectManagement/Project-task/New-task/Newtask";
import EditTemplate from "./ProjectManagement/Project/Project_template/Edit/EditTemplate";
import Editproject from "./ProjectManagement/Project/Editproject/Editproject";
import TimeLog from "./ProjectManagement/Time log/TimeLog";
import Edittask from "./ProjectManagement/Project-task/Edit-task/Edittask";
import Client from "./ClientManagement/Client";
import AddNewClient from "./ClientManagement/AddNewClient/AddNewClient";
import TaskLabel from "../Admin/ProjectManagement/Task_Label/Table";
import TaskPriority from "../Admin/ProjectManagement/Task_Priority/Table";
import EditClient from "./ClientManagement/EditClient/EditClient";
import PageNotFound from "../PageNotFound/PageNotFound";
import { IDS } from "../../utils/common";
import { Context } from "../../utils/context";

const IndexAdmin = () => {
  const { isAllow } = useContext(Context);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);
  useEffect(() => {
    setHeaderText(location.pathname);
  }, []);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "Header",
        element: <Header />,
        status: true,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        status: true,
      },
      {
        path: "/advanceDashboard",
        element: <AdvanceDashboard />,
        status: true,
      },
      {
        path: "/masters/country",
        element: <Country />,
        status: isAllow.includes(IDS.Country.List),
      },
      {
        path: "/masters/state",
        element: <State />,
        status: isAllow.includes(IDS.State.List),
      },
      {
        path: "/masters/city",
        element: <City />,
        status: isAllow.includes(IDS.City.List),
      },
      {
        path: "/masters/zone",
        element: <Zone />,
        status: isAllow.includes(IDS.Zone.List),
      },
      {
        path: "/masters/pincode",
        element: <Pincode />,
        status: isAllow.includes(IDS.Pincode.List),
      },
      {
        path: "/masters/department",
        element: <Departments />,
        status: isAllow.includes(IDS.Department.List),
      },
      {
        path: "/masters/education_list",
        element: <Education_List />,
        status: isAllow.includes(IDS.Education.List),
      },
      {
        path: "/masters/order_status",
        element: <Order_Status />,
        status: isAllow.includes(IDS.OrderStatus.List),
      },
      {
        path: "/masters/payment_status",
        element: <Payment_Status />,
        status: isAllow.includes(IDS.PaymentStatus.List),
      },
      {
        path: "/masters/gstlist",
        element: <GSTlist />,
        status: isAllow.includes(IDS.GSTList.List),
      },
      {
        path: "/ticket_management/chanel_name",
        element: <Chanel_name />,
        status: true,
      },
      {
        path: "/ticket_management/ticket_status",
        element: <Ticket_status />,
        status: isAllow.includes(IDS.TicketStatus.List),
      },
      {
        path: "/masters/country_code",
        element: <Country_Code />,
        status: isAllow.includes(IDS.CountryCode.List),
      },
      {
        path: "/masters/initial",
        element: <Initial />,
        status: isAllow.includes(IDS.Initial.List),
      },
      {
        path: "/masters/currency",
        element: <Currency />,
        status: isAllow.includes(IDS.Currency.List),
      },
      {
        path: "/masters/complaint_types",
        element: <ComplaintTypes />,
        status: isAllow.includes(IDS.ComplaintType.List),
      },
      {
        path: "/masters/visit_types",
        element: <VisitType />,
        status: isAllow.includes(IDS.VisitType.List),
      },
      {
        path: "/masters/complaint_source",
        element: <ComplaintSource />,
        status: isAllow.includes(IDS.ComplaintSource.List),
      },
      {
        path: "/masters/complaint_priorities",
        element: <ComplaintPriorities />,
        status: isAllow.includes(IDS.ComplaintPriorities.List),
      },
      {
        path: "/masters/complaint_status",
        element: <ComplaintStatus />,
        status: isAllow.includes(IDS.ComplaintStatus.List),
      },
      {
        path: "/masters/visit_rate",
        element: <VisitRate />,
        status: isAllow.includes(IDS.VisitRate.List),
      },
      {
        path: "/masters/customer",
        element: <Customer />,
        status: true,
      },
      {
        path: "/ticket_management/chanel_name",
        element: <Chanel_name />,
        status: true,
      },
      {
        path: "/ticket_management/ticket_types",
        element: <Ticket_types />,
        status: isAllow.includes(IDS.TicketTypes.List),
      },
      {
        path: "/ticket_management/priorities",
        element: <Priorities />,
        status: isAllow.includes(IDS.TicketPriorities.List),
      },
      {
        path: "/ticket_management/ticket_generates",
        element: <Ticket_generates />,
        status: true,
      },
      {
        path: "/hrm/department",
        element: <Department />,
        status: isAllow.includes(IDS.Department.List),
      },
      {
        path: "/hrm/designation",
        element: <Designation />,
        status: isAllow.includes(IDS.Designation.List),
      },
      {
        path: "/hrm/skills",
        element: <Skills />,
        status: isAllow.includes(IDS.Skills.List),
      },
      {
        path: "/hrm/leave_type",
        element: <LeaveType />,
        status: isAllow.includes(IDS.LeaveType.List),
      },
      {
        path: "/hrm/leave",
        element: <Leave />,
        status: isAllow.includes(IDS.Leave.List),
      },
      {
        path: "/hrm/holiday",
        element: <Holiday />,
        status: isAllow.includes(IDS.Holiday.List),
      },
      {
        path: "/hrm/attendance",
        element: <AttendanceTable />,
        status: true,
      },
      {
        path: "/route_management/routelist",
        element: <RouteList />,
        status: isAllow.includes(IDS.Routelist.List),
      },
      {
        path: "/product_management/product_list",
        element: <ProductList />,
        status: isAllow.includes(IDS.ProductList.List),
      },
      {
        path: "/employee/role",
        element: <EmployeeRole />,
        status: isAllow.includes(IDS.Role.List),
      },
      {
        path: "/employee/employee_details",
        element: <EmployeeDetails />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/lead_management/leads",
        element: <AllLeads />,
        status: true,
      },
      {
        path: "/lead_management/lead_status",
        element: <LeadStatus />,
        status: isAllow.includes(IDS.LeadStatus.List),
      },
      {
        path: "/lead_management/lead_status_details",
        element: <LeadStatusDetails />,
        status: isAllow.includes(IDS.LeadStatusDetails.List),
      },
      {
        path: "/lead_management/document",
        element: <Document />,
        status: isAllow.includes(IDS.Document.List),
      },
      {
        path: "/lead_management/service",
        element: <Service />,
        status: isAllow.includes(IDS.Service.List),
      },
      {
        path: "/co_ordinator_management/manager",
        element: <CoOrdinatorManagementManager />,
        status: isAllow.includes(IDS.Manager.List),
      },
      {
        path: "/co_ordinator_management/employee_under_manager",
        element: <CoOrdinatorManagementSalesCoOrdinater />,
        status: isAllow.includes(IDS.SalesCoOrdinater.List),
      },
      {
        path: "/co_ordinator_management/sales_executive",
        element: <CoOrdinatorManagementSalesExecutive />,
        status: isAllow.includes(IDS.SalesExecutive.List),
      },

      {
        path: "/app_setup",
        element: <AppSetup />,
        status: isAllow.includes(IDS.Role.List),
      },
    ]);
  }, [isAllow]);

  return (
    <>
      <div className="main d-flex">
        <Sidebar />
        <div
          style={{
            width: "100%",
            overflowY: "auto",
            height: "100vh",
            // marginRight: "15px",
          }}
        >
          <Routes>
            {urls?.map(
              (url) =>
                url.status && <Route path={url.path} element={url.element} />
            )}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default IndexAdmin;
